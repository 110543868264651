import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Kiitos = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Kiitos" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Kiitos viestistäsi</h1>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="spacer-60" />
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <p>Palaamme asiaan pikimmitten!</p>
        </div>
        <div className="spacer-35" />
      </div>
      <div className="spacer-35" />
    </div>
  </Layout>
)

export default Kiitos
